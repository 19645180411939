import { HTTPClientService } from "services/HTTPClient";
import { getAPIBase } from "util/MagnoliaAppHelpers";

export interface ContactEmail {
  target: "ALBIS" | "PROTOFY" | "KRAHN_CHEMIE";
  senderEmail: string;
  senderName: string;
  senderCompany: string;
  messageSubject: string;
  message: string;
  street?: string;
  apartmentNumber?: string;
  plz?: string;
  city?: string;
  senderCountry?: string;
  _altcha: string;
}
export interface HTFContactEmail {
  target: "ALBIS" | "HTF" | "PROTOFY";
  senderEmail: string;
  senderName: string;
  senderCompany: string;
  street: string;
  apartmentNumber?: string;
  plz: string;
  city: string;
  message: string;
  senderCountry?: string;
  _altcha: string;
}

export interface OKGInnovationCustomerMail {
  target: "INNOVATION_EMAIL" | "INNOVATION_EMAIL_PROTOFY";
  senderEmail: string;
  language: "de" | "en";
}

export interface OKGInnovationMail {
  target: "INNOVATION" | "PROTOFY_INNOVATION";
  senderName: string;
  senderEmail: string;
  startup: string;
  comment?: string;
  privacy: boolean;
  _altcha: string;
}

export interface ALBISStartConversationMail {
  target: "PROD" | "PROTOFY";
  company: string;
  name: string;
  email: string;
  phone?: string;
  countryCode: string;
  zipCode: string;
  product?: string;
  application?: string;
  technicalPrecondition?: string;
  color?: string;
  volume?: string;
  additionalNotes?: string;
  interestedInOtherProduts: boolean;
  privacyAgreementAccepted: boolean;
  documents: File[];
  _altcha: string;
}

export interface ALBISContactRequest {
  countryCode: string;
  zipcode: string;
  email: string;
  name: string;
  callbackRequested: boolean;
  phone: string;
  contactReason: string;
  privacyAgreementAccepted: boolean;
  _altcha: string;
}

const basePath = getAPIBase();
const restContactMailBase =
  basePath + process.env.REACT_APP_MGNL_API_CONTACT_MAIL;

const restProductMail =
  basePath + process.env.REACT_APP_MGNL_API_CONTACT_PRODUCT;

const testVerifyAltcha = basePath + "/.rest/altcha/verify";
export default class ContactService {
  readonly httpClientService: HTTPClientService;

  constructor(httpClientService: HTTPClientService) {
    this.httpClientService = httpClientService;
  }

  sendContactEmail = async (
    mail: ContactEmail | HTFContactEmail | OKGInnovationMail
  ) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    return await httpClient.post(restContactMailBase, mail);
  };

  sendCustomerMail = async (mail: OKGInnovationCustomerMail) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    return await httpClient.post(restContactMailBase, mail);
  };

  sendStartConversationMail = async (mail: ALBISStartConversationMail) => {
    const httpClient = this.httpClientService.getMultipartClient();
    return await httpClient.post(restProductMail, mail);
  };

  sendVerify = async (challenge: unknown) => {
    const httpClient = this.httpClientService.getAlbisHttpClient();
    return await httpClient.post(testVerifyAltcha, {
      altcha: challenge,
    });
  };
}
