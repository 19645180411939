import React from "react";
import { wrapWithErrorBoundary as wrap } from "components/magnolia/Basics/MagnoliaErrorBoundary";
import ExitSPA from "pages/ExitSPA";
import Redirect from "pages/Redirect";

// TODO: see later comment
const MagnoliaStageSlider = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaStageSlider")
);

const BasicAlbisOnePager = React.lazy(() => import("pages/BasicAlbisOnepager"));
const BasicHTF = React.lazy(() => import("pages/BasicHTF"));
const BasicKrahnCeramics = React.lazy(
  () => import("pages/BasicKrahnCeramics2021")
);
const BasicKrahnChemie = React.lazy(() => import("pages/BasicKrahnChemie"));
const BasicMocom = React.lazy(() => import("pages/BasicMocom"));
const BasicOKNewBusiness = React.lazy(() => import("pages/BasicOKNewBusiness"));
const BasicOttoKrahnGroup = React.lazy(
  () => import("pages/BasicOttoKrahnGroup")
);
const BasicWIPAG = React.lazy(() => import("pages/BasicWIPAG"));
const BasicWIPAGOnepager = React.lazy(() => import("pages/BasicWIPAGOnepager"));

const BasicNewFrontendPlayground = React.lazy(
  () => import("pages/NewFrontendPlayground")
);

const BasicAlbis = React.lazy(() => import("pages/BasicAlbis"));

const MagnoliaKCRichTextGroup = React.lazy(
  () => import("components/krahn-ceramics/magnolia/MagnoliaKCRichtextGroup")
);

const MagnoliaKCTextImageTeaser = React.lazy(
  () => import("components/krahn-ceramics/magnolia/MagnoliaKCTextImageTeaser")
);
const MagnoliaExpandableContent = React.lazy(
  () => import("components/magnolia/MagnoliaExpandableContent")
);

const MagnoliaDynamicDepartmentContent = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaDynamicDepartmentContent")
);

const FloatingApplyButton = React.lazy(
  () => import("components/jobpages/components/FloatingApplyButton")
);

const MagnoliaJobBoard = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaJobBoard")
);

const MagnoliaKChFactsAndFigures = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaFactsAndFigures")
);

const MagnoliaContentTeaserComponent = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaContentTeaserComponent")
);

const MagnoliaServicesStage = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaServicesStage")
);

const MagnoliaKChLocationsMap = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaKChLocationsMap")
);

const MagnoliaImageInfoSlider = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaImageInfoSlider")
);

const MagnoliaOKGPlaces = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGPlaces")
);
const MagnoliaKC2021Downloads = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Downloads")
);

const MagnoliaWIPAGCombinedContact = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGCombinedContact")
);
const MagnoliaWIPAGContentTeaserSection = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGContentTeaserSection")
);
const MagnoliaWIPAGContact = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGContact")
);
const MagnoliaWIPAGDividerSlider = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGDividerSlider")
);
const MagnoliaWIPAGStage = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGStage")
);
const MagnoliaWIPAGImageTextComponent = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGTextImageComponent")
);
const MagnoliaWIPAGLocations = React.lazy(
  () => import("components/WIPAG/magnolia/WIPAGLocations")
);
const MagnoliaWIPAGDividerText = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGDividerText")
);
const MagnoliaWIPAGTextWithImageTeaser = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGTextWithImageTeaser")
);
const MagnoliaWIPAGCaseStudies = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGCaseStudies")
);
const MagnoliaWIPAGSmallStage = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGSmallStage")
);
const MagnoliaWIPAGDistributorApp = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGProductApp")
);

const MagnoliaWIPARichTextSection = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGRichTextSection")
);
const MagnoliaWIPAGDownloads = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGDownloads")
);
const MagnoliaWIPAGBenefitsSlider = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGBenefitSlider")
);
const MagnoliaWIPAGJobTeaser = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGJobTeaser")
);
const MagnoliaWIPAGHeadline = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGHeadline")
);

const MagnoliaWIPAGSmallImageTextComponent = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaWIPAGSmallImageTextComponent")
);

const PlaygroundMainContent = React.lazy(
  () => import("components/playground/PlaygroundMainContent")
);

const MagnoliaFooter = React.lazy(
  () => import("components/magnolia/Basics/MagnoliaFooter")
);
const MagnoliaImageTextComponent = React.lazy(
  () => import("components/magnolia/MagnoliaImageTextComponent")
);
const MagnoliaContentTeaserSection = React.lazy(
  () => import("components/magnolia/MagnoliaContentTeaserSection")
);
const MagnoliaLogoSlider = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaLogoSlider")
);

// TODO -> when lazy load: comonent is to big -> because navigation heigt is 0: see
// getHeaderHeight in HeaderHelper on http://localhost:41812/albis-distribution/de
// const MagnoliaStageSlider = React.lazy(
//   () => import("components/magnolia/ALBIS/MagnoliaStageSlider")
// );

const MagnoliaStage = React.lazy(
  () => import("components/magnolia/MagnoliaStage")
);
const MagnoliaFactsFigures = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaFactsFigures")
);
const MagnoliaDividerText = React.lazy(
  () => import("components/magnolia/Basics/MagnoliaDividerText")
);
const MagnoliaBrandOverview = React.lazy(
  () => import("components/magnolia/MagnoliaBrandOverview")
);
const MagnoliaRichTextSection = React.lazy(
  () => import("components/magnolia/Basics/MagnoliaRichTextSection")
);
const MagnoliaContactTextTeaser = React.lazy(
  () => import("components/magnolia/MagnoliaContactTextTeaser")
);
const MagnoliaProductSearchAlbis = React.lazy(
  () => import("components/magnolia/MagnoliaProductSearch")
);
const MagnoliaMocomStageSlider = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaMocomStageSlider")
);
const MagnoliaImageTextSlider = React.lazy(
  () => import("components/magnolia/MagnoliaImageTextSlider")
);
const MagnoliaTextOverlappingImageSection = React.lazy(
  () => import("components/magnolia/MagnoliaTextOverlappingImageSection")
);
const MagnoliaContactLocationSection = React.lazy(
  () => import("components/magnolia/MagnoliaContactLocationSection")
);
const MagnoliaContactStage = React.lazy(
  () => import("components/magnolia/MagnoliaContactStage")
);
const MagnoliaContactForm = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaContactForm")
);
const MagnoliaDistributorApp = React.lazy(
  () => import("components/mocom/magnolia/MocomProductApp")
);
const MagnoliaNewsletterSectionForm = React.lazy(
  () => import("components/magnolia/MagnoliaNewsletterSection")
);
const MagnoliaMocomFactsFigures = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaMocomFactsAndFigures")
);
const MagnoliaJobTeaser = React.lazy(
  () => import("components/magnolia/MagnoliaJobTeaser")
);

const MagnoliaContactCard = React.lazy(
  () => import("components/magnolia/Media/MagnoliaContactCard")
);
const MagnoliaMediaApp = React.lazy(
  () => import("components/magnolia/Media/MagnoliaMediaApp")
);

const KC2021ImageTextSlider = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021ImageTextSlider")
);
const KC2021Header = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Header")
);
const KC2021Footer = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Footer")
);
const KC2021MagnoliaRichTextSection = React.lazy(
  () =>
    import("components/krahn-ceramics/magnolia/KC2021MagnoliaRichTextSection")
);
const MagnoliaKC2021Process = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Process")
);

const MagnoliaListArea = React.lazy(
  () => import("components/magnolia/MagnoliaListArea")
);
const MagnoliaEventsApp = React.lazy(
  () => import("components/magnolia/Media/MagnoliaEventsApp")
);
const MagnoliaContactSection = React.lazy(
  () => import("components/magnolia/MagnoliaContactSection")
);
const MagnoliaCustomerContactForm = React.lazy(
  () => import("components/magnolia/MagnoliaCustomerContactForm")
);
const MagnoliaDownloadsAndLinks = React.lazy(
  () => import("components/magnolia/MagnoliaDownloadsAndLinks")
);
const MagnoliaTextWithImageTeaser = React.lazy(
  () => import("components/magnolia/MagnoliaTextWithImageTeaser")
);
const MagnoliaSmallImageTextComponent = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaSmallImageTextComponent")
);
const MagnoliaTileGallery = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaTileGallery")
);
const MagnoliaPartnerSection = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaPartnerSection")
);
const MagnoliaInteractiveHomeMocom = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveHomeMocom")
);
const MagnoliaInteractiveSportsMocom = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveSportsMocom")
);
const MagnoliaInteractiveAutomotive = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveAutomotiveMocom")
);
const MagnoliaInteractiveTransportation = React.lazy(
  () =>
    import("components/mocom/magnolia/MagnoliaInteractiveTransportationMocom")
);
const MagnoliaInteractiveElectrics = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveElectricsMocom")
);
const MagnoliaInteractiveIndustrial = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveIndustrialMocom")
);
const MagnoliaInteractiveHealthcareMocom = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaInteractiveHealthcareMocom")
);
const MagnoliaInteractiveMobilityAlbis = React.lazy(
  () =>
    import("components/magnolia/Markets/ALBIS/MagnoliaInteractiveMobilityAlbis")
);
const MagnoliaOKGStageSlider = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGStageSlider")
);
const MagnoliaOKGAboutUs = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGAboutUs")
);
const MagnoliaOKGStatement = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaStatement")
);
const MagnoliaOKGVision = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGVision")
);
const MagnoliaOKGFooter = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGFooter")
);
const MagnoliaOKGDownloads = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGDownloads")
);
const MagnoliaALBISProductApp = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaALBISProductApp")
);
const MagnoliaOKGHistory = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGHistory")
);
const MagnoliaFactsAndFigures = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGFactsAndFigures")
);
const MagnoliaLocations = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGLocations")
);
const MagnoliaOKGBrands = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGBrands")
);
const MagnoliaElementSlider = React.lazy(
  () => import("components/magnolia/MagnoliaElementSlider")
);
const MagnoliaInteractiveBuildingAlbis = React.lazy(
  () =>
    import("components/magnolia/Markets/ALBIS/MagnoliaInteractiveBuildingALBIS")
);
const MagnoliaInteractiveElectricsAlbis = React.lazy(
  () =>
    import(
      "components/magnolia/Markets/ALBIS/MagnoliaInteractiveElectricsAlbis"
    )
);
const MagnoliaInteractiveHealthcareAlbis = React.lazy(
  () =>
    import(
      "components/magnolia/Markets/ALBIS/MagnoliaInteractiveHealthcareAlbis"
    )
);
const MagnoliaInteractiveSportsAlbis = React.lazy(
  () =>
    import("components/magnolia/Markets/ALBIS/MagnoliaInteractiveSportsAlbis")
);
const MagnoliaInteractivePackagingAlbis = React.lazy(
  () => import("components/magnolia/Markets/ALBIS/MagnoliaInteractivePackaging")
);
const MagnoliaInteractiveSustainableSolutionsAlbis = React.lazy(
  () =>
    import(
      "components/magnolia/Markets/ALBIS/MagnoliaInteractiveSustainableSolutionsAlbis"
    )
);
const MagnoliaBack = React.lazy(
  () => import("components/magnolia/Basics/MagnoliaBack")
);
const MagnoliaHRAFForm = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaHRAFForm")
);
const MagnoliaBrochuresApp = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaBroschuresApp")
);
const MagnoliaTechnicalServiceGraphic = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaTechnicalServiceGraphic")
);

const MagnoliaOnePagerFooter = React.lazy(
  () => import("components/magnolia/MagnoliaOnePagerFooter")
);
const MagnoliaHTFNavigation = React.lazy(
  () => import("components/htf/magnolia/MagnoliaHTFNavigation")
);
const MagnoliaHTFProductApp = React.lazy(
  () => import("components/htf/magnolia/MagnoliaHTFProductApp")
);
const MagnoliaHeadline = React.lazy(
  () => import("components/magnolia/MagnoliaHeadline")
);
const MagnoliaHTFAnalysisService = React.lazy(
  () => import("components/htf/magnolia/MagnoliaAnalysisService")
);

const HTFBackButton = React.lazy(
  () => import("components/htf/magnolia/HTFBackButton")
);
const SearchResults = React.lazy(
  () => import("components/magnolia/MagnoliaSearchResults")
);
const MagnoliaHTFImageTextTeaser = React.lazy(
  () => import("components/htf/magnolia/MagnoliaHTFImageTextTeaser")
);

const MagnoliaHTFDividerText = React.lazy(
  () => import("components/htf/magnolia/MagnoliaHTFDividerText")
);
const MagnoliaHTFBanner = React.lazy(
  () => import("components/htf/magnolia/MagnoliaHTFBanner")
);

const MagnoliaKC2021Partners = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Partners")
);

const MagnoliaKC2021Stage = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KC2021Stage")
);

const MagnoliaKC2021News = React.lazy(
  () => import("components/krahn-ceramics/magnolia/KCNewsComponent")
);
const MagnoliaImageComponent = React.lazy(
  () => import("components/magnolia/Basics/MagnoliaImageComponent")
);

const MagnoliaOKGTextImageComponent = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGTextImageComponent")
);

const MagnoliaOKGInnovationForm = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGInnovationForm")
);
const MagnoliaCO2Component = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaCO2Component")
);

const MagnoliaOKGDividerText = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGDividerText")
);

const MagnoliaOKGSmallStage = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGSmallStage")
);
const MagnoliaOKGContentTeaserSection = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGContentTeaserSection")
);
const MagnoliaBrandTeaser = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaBrandTeaser")
);
const MagnoliaOKGPressTeaser = React.lazy(
  () => import("components/OKG/magnolia/MagnoliaOKGPressTeaser")
);

const MagnoliaCookiebotReset = React.lazy(
  () => import("components/magnolia/MagnoliaCookiebotReset")
);

const MagnoliaClientAreaApp = React.lazy(
  () => import("components/magnolia/ClientArea/MagnoliaClientAreaApp")
);

const MagnoliaContactFinder = React.lazy(
  () => import("components/magnolia/ClientArea/MagnoliaContactFinder")
);
const MagnoliaFAQComponent = React.lazy(
  () => import("components/mocom/magnolia/MagnoliaFAQComponent")
);

const MagnoliaRecyclingForm = React.lazy(
  () => import("components/WIPAG/magnolia/MagnoliaRecyclingForm")
);

const MagnoliaLatestNews = React.lazy(
  () => import("components/magnolia/ALBIS/MagnoliaLatestNewsComponent")
);

const MagnoliaOnePagerHeader = React.lazy(
  () => import("components/magnolia/MagnoliaOnePageHeader")
);

// krahnChemie
const MagnoliaHomeStageSlider = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaHomeStageSlider")
);
const MagnoliaAnimatedStageSlider = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaAnimatedStageSlider")
);
const MagnoliaHistory = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaHistory")
);
const MagnoliaKChImageTextComponent = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaKChImageTextComponent")
);
const MagnoliaFAQComponentWithContact = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaFAQWithContact")
);
const MagnoliaKChProductsApp = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaProductApp")
);

const MagnoliaOKNBNavigation = React.lazy(
  () => import("components/oknb/magnolia/MagnoliaOKNBNavigation")
);

const MagnoliaCustomerContactFormKCh = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaCustomerContactForm")
);

const MagnoliaTwoTextComponent = React.lazy(
  () => import("components/krahnChemie/magnolia/MagnoliaTwoTextComponent")
);

// job pages
const MagnoliaJobCTAArea = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaJobCTAArea")
);
const MagnoliaBenefits = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaBenefits")
);
const MagnoliaApplicationInfo = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaApplicationInfo")
);
const MagnoliaJobContact = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaJobContact")
);
const MagnoliaImagesSlider = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaImagesSlider")
);
const MagnoliaDynamicJobContent = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaDynamicJobContent")
);
const MagnoliaDynamicJobsList = React.lazy(
  () => import("components/jobpages/magnolia/MagnoliaDynamicJobsList")
);
const Magnolia3QEmbed = React.lazy(
  () => import("components/magnolia/Magnolia3QEmbed")
);

const config = {
  // BASIC COMPONENTS
  "albis-react:pages/home": wrap(BasicAlbis),
  "albis-react:pages/basic": wrap(BasicAlbis),
  "albis-react:pages/basicMocom": wrap(BasicMocom),
  "albis-react:pages/basicKrahnCeramics2021": wrap(BasicKrahnCeramics),
  "albis-react:pages/basicOttoKrahnGroup": wrap(BasicOttoKrahnGroup),
  "albis-react:pages/basicHTF": wrap(BasicHTF),
  "albis-react:pages/NewFrontendPlayground": wrap(BasicNewFrontendPlayground),
  "albis-react:pages/basicWipag": wrap(BasicWIPAG),
  "albis-react:pages/basicWipagOnepager": wrap(BasicWIPAGOnepager),
  "albis-react:pages/basicALBISOnePager": wrap(BasicAlbisOnePager),
  "albis-react:pages/basicKrahnChemie": wrap(BasicKrahnChemie),
  "albis-react:pages/basicOKNB": wrap(BasicOKNewBusiness),

  // start compatibility
  "albis:pages/redirect": wrap(Redirect),
  "albis:pages/search": wrap(ExitSPA),
  "albis:pages/main": wrap(ExitSPA),
  // end compatibility

  "albis-react:components/albis/footer": wrap(MagnoliaFooter),

  "albis-react:components/albis/albisFactsFigures": wrap(
    MagnoliaMocomFactsFigures
  ),
  "albis-react:components/albis/albisInteractiveBuilding": wrap(
    MagnoliaInteractiveBuildingAlbis
  ),
  "albis-react:components/albis/albisInteractiveElectrics": wrap(
    MagnoliaInteractiveElectricsAlbis
  ),
  "albis-react:components/albis/albisInteractiveHealthcare": wrap(
    MagnoliaInteractiveHealthcareAlbis
  ),
  "albis-react:components/albis/albisInteractiveMobility": wrap(
    MagnoliaInteractiveMobilityAlbis
  ),
  "albis-react:components/albis/albisInteractivePackaging": wrap(
    MagnoliaInteractivePackagingAlbis
  ),
  "albis-react:components/albis/albisInteractiveSports": wrap(
    MagnoliaInteractiveSportsAlbis
  ),
  "albis-react:components/albis/albisInteractiveSustainableSolutions": wrap(
    MagnoliaInteractiveSustainableSolutionsAlbis
  ),
  "albis-react:components/albis/onePagerFooter": wrap(MagnoliaOnePagerFooter),
  "albis-react:components/albis/onePagerNavi": wrap(MagnoliaOnePagerHeader),

  "albis-react:components/albis/albisProductSearch": wrap(
    MagnoliaProductSearchAlbis
  ),
  "albis-react:components/albis/backButton": wrap(MagnoliaBack),
  "albis-react:components/albis/brandOverview": wrap(MagnoliaBrandOverview),
  "albis-react:components/albis/brochuresApp": wrap(MagnoliaBrochuresApp),
  "albis-react:components/albis/contactCard": wrap(MagnoliaContactCard),
  "albis-react:components/albis/customerContactForm": wrap(
    MagnoliaCustomerContactForm
  ),
  "albis-react:components/albis/contactLocationSection": wrap(
    MagnoliaContactLocationSection
  ),
  "albis-react:components/albis/contactSection": wrap(MagnoliaContactSection),
  "albis-react:components/albis/contactStage": wrap(MagnoliaContactStage),
  "albis-react:components/albis/contactTextTeaser": wrap(
    MagnoliaContactTextTeaser
  ),
  "albis-react:components/albis/contentTeaserSection": wrap(
    MagnoliaContentTeaserSection
  ),
  "albis-react:components/albis/distributorApp": wrap(MagnoliaALBISProductApp),
  "albis-react:components/albis/dividerText": wrap(MagnoliaDividerText),
  "albis-react:components/albis/downloadsAndLinks": wrap(
    MagnoliaDownloadsAndLinks
  ),
  "albis-react:components/albis/elementSlider": wrap(MagnoliaElementSlider),
  "albis-react:components/albis/eventsApp": wrap(MagnoliaEventsApp),
  "albis-react:components/albis/factsFigures": wrap(MagnoliaFactsFigures),
  "albis-react:components/albis/headline": wrap(MagnoliaHeadline),
  "albis-react:components/albis/hrafForm": wrap(MagnoliaHRAFForm),
  "albis-react:components/albis/imageComponent": wrap(MagnoliaImageComponent),
  "albis-react:components/albis/imageSlider": wrap(MagnoliaLogoSlider),
  "albis-react:components/albis/imageTextSlider": wrap(MagnoliaImageTextSlider),
  "albis-react:components/albis/imageTextTeaser": wrap(
    MagnoliaImageTextComponent
  ),
  "albis-react:components/albis/jobTeaser": wrap(MagnoliaJobTeaser),
  "albis-react:components/albis/listArea": wrap(MagnoliaListArea),
  "albis-react:components/albis/marketsGallery": wrap(MagnoliaTileGallery),
  "albis-react:components/albis/mediaApp": wrap(MagnoliaMediaApp),
  "albis-react:components/albis/newsletterSection": wrap(
    MagnoliaNewsletterSectionForm
  ),
  "albis-react:components/albis/partnerSection": wrap(MagnoliaPartnerSection),
  "albis-react:components/albis/smallImageTextComponent": wrap(
    MagnoliaSmallImageTextComponent
  ),
  "albis-react:components/albis/richtextSection": wrap(MagnoliaRichTextSection),
  "albis-react:components/albis/searchResults": wrap(SearchResults),
  "albis-react:components/albis/stage": wrap(MagnoliaStage),
  "albis-react:components/albis/stageSlider": wrap(MagnoliaStageSlider),
  "albis-react:components/albis/technicalServiceGraphic": wrap(
    MagnoliaTechnicalServiceGraphic
  ),
  "albis-react:components/albis/textOverlappingImageSection": wrap(
    MagnoliaTextOverlappingImageSection
  ),
  "albis-react:components/albis/textWithImageTeaser": wrap(
    MagnoliaTextWithImageTeaser
  ),
  "albis-react:components/albis/faqComponent": wrap(MagnoliaFAQComponent),

  "albis-react:components/albis/latestNews": wrap(MagnoliaLatestNews),
  "albis-react:components/albis/faqWithContact": wrap(
    MagnoliaFAQComponentWithContact
  ),
  "albis-react:components/albis/expandableContent": wrap(
    MagnoliaExpandableContent
  ),

  // KRAHN-CERAMICS
  "albis-react:components/krahnCeramics/kcRichTextGroup": wrap(
    MagnoliaKCRichTextGroup
  ),
  "albis-react:components/krahnCeramics/krahnCeramicsTextImageTeaser": wrap(
    MagnoliaKCTextImageTeaser
  ),

  // KRAHN-CERAMICS 2021
  "albis-react:components/krahnCeramics/kcFooter": wrap(KC2021Footer),
  "albis-react:components/krahnCeramics/kcHeader": wrap(KC2021Header),
  "albis-react:components/krahnCeramics/kcImageTextSlider": wrap(
    KC2021ImageTextSlider
  ),
  "albis-react:components/krahnCeramics/kcRichTextSection": wrap(
    KC2021MagnoliaRichTextSection
  ),
  "albis-react:components/krahnCeramics/kcDownloads": wrap(
    MagnoliaKC2021Downloads
  ),
  "albis-react:components/krahnCeramics/krahnCeramicsProcess": wrap(
    MagnoliaKC2021Process
  ),
  "albis-react:components/krahnCeramics/krahnCeramicsPartners": wrap(
    MagnoliaKC2021Partners
  ),
  "albis-react:components/krahnCeramics/krahnCeramicsStage": wrap(
    MagnoliaKC2021Stage
  ),
  "albis-react:components/krahnCeramics/krahnCeramicsNews": wrap(
    MagnoliaKC2021News
  ),
  "albis-react:components/krahnCeramics/imageTextTeaser": wrap(
    MagnoliaImageTextComponent
  ),

  // MOCOM
  "albis-react:components/mocom/mocomBackButton": wrap(MagnoliaBack),
  "albis-react:components/mocom/mocomBenefitSlider": wrap(
    MagnoliaImageTextSlider
  ),

  "albis-react:components/mocom/mocomContactCard": wrap(MagnoliaContactCard),
  "albis-react:components/mocom/mocomContactForm": wrap(MagnoliaContactForm),
  "albis-react:components/mocom/mocomContactLocationSection": wrap(
    MagnoliaContactLocationSection
  ),
  "albis-react:components/mocom/mocomContactSection": wrap(
    MagnoliaContactSection
  ),
  "albis-react:components/mocom/mocomContactStage": wrap(MagnoliaContactStage),
  "albis-react:components/mocom/mocomContentTeaserSection": wrap(
    MagnoliaContentTeaserSection
  ),
  "albis-react:components/mocom/mocomContactTextTeaser": wrap(
    MagnoliaContactTextTeaser
  ),
  "albis-react:components/mocom/mocomDividerText": wrap(MagnoliaDividerText),
  "albis-react:components/mocom/mocomDistributorApp": wrap(
    MagnoliaDistributorApp
  ),
  "albis-react:components/mocom/mocomDownloadsAndLinks": wrap(
    MagnoliaDownloadsAndLinks
  ),
  "albis-react:components/mocom/mocomEventsApp": wrap(MagnoliaEventsApp),
  "albis-react:components/mocom/mocomFactsFigures": wrap(
    MagnoliaMocomFactsFigures
  ),
  "albis-react:components/mocom/mocomImageTextTeaser": wrap(
    MagnoliaImageTextComponent
  ),
  "albis-react:components/mocom/mocomInteractiveAutomotive": wrap(
    MagnoliaInteractiveAutomotive
  ),
  "albis-react:components/mocom/mocomInteractiveElectrics": wrap(
    MagnoliaInteractiveElectrics
  ),
  "albis-react:components/mocom/mocomInteractiveHealthcare": wrap(
    MagnoliaInteractiveHealthcareMocom
  ),
  "albis-react:components/mocom/mocomInteractiveHome": wrap(
    MagnoliaInteractiveHomeMocom
  ),
  "albis-react:components/mocom/mocomInteractiveIndustrial": wrap(
    MagnoliaInteractiveIndustrial
  ),
  "albis-react:components/mocom/mocomInteractiveSports": wrap(
    MagnoliaInteractiveSportsMocom
  ),
  "albis-react:components/mocom/mocomInteractiveTransportation": wrap(
    MagnoliaInteractiveTransportation
  ),
  "albis-react:components/mocom/mocomJobTeaser": wrap(MagnoliaJobTeaser),
  "albis-react:components/mocom/mocomMediaApp": wrap(MagnoliaMediaApp),
  "albis-react:components/mocom/mocomProductSearch": wrap(
    MagnoliaProductSearchAlbis
  ),
  "albis-react:components/mocom/mocomStageSlider": wrap(
    MagnoliaMocomStageSlider
  ),
  "albis-react:components/mocom/mocomTestimonialSection": wrap(
    MagnoliaSmallImageTextComponent
  ),
  "albis-react:components/mocom/mocomTextWithImageTeaser": wrap(
    MagnoliaTextWithImageTeaser
  ),
  "albis-react:components/mocom/mocomImageTextSlider": wrap(
    MagnoliaImageTextSlider
  ),
  "albis-react:components/mocom/elementSlider": wrap(MagnoliaElementSlider),
  "albis-react:components/mocom/headline": wrap(MagnoliaHeadline),
  "albis-react:components/mocom/imageSlider": wrap(MagnoliaLogoSlider),
  "albis-react:components/mocom/footer": wrap(MagnoliaFooter),
  "albis-react:components/mocom/searchResults": wrap(SearchResults),
  "albis-react:components/mocom/textOverlappingImageSection": wrap(
    MagnoliaTextOverlappingImageSection
  ),
  "albis-react:components/mocom/stage": wrap(MagnoliaStage),
  "albis-react:components/mocom/richtextSection": wrap(MagnoliaRichTextSection),
  "albis-react:components/mocom/faqComponent": wrap(MagnoliaFAQComponent),

  // OKG
  "albis-react:components/okg/okgAboutUs": wrap(MagnoliaOKGAboutUs),
  "albis-react:components/okg/okgBrands": wrap(MagnoliaOKGBrands),
  "albis-react:components/okg/okgFactsAndFigures": wrap(
    MagnoliaFactsAndFigures
  ),
  "albis-react:components/okg/okgFooter": wrap(MagnoliaOKGFooter),
  "albis-react:components/okg/okgHistory": wrap(MagnoliaOKGHistory),
  "albis-react:components/okg/okgLocations": wrap(MagnoliaLocations),
  "albis-react:components/okg/okgRichTextSection": wrap(
    MagnoliaRichTextSection
  ),
  "albis-react:components/okg/okgStageSlider": wrap(MagnoliaOKGStageSlider),
  "albis-react:components/okg/okgStatement": wrap(MagnoliaOKGStatement),
  "albis-react:components/okg/okgVisions": wrap(MagnoliaOKGVision),
  "albis-react:components/okg/okgDownloads": wrap(MagnoliaOKGDownloads),
  "albis-react:components/okg/imageComponent": wrap(MagnoliaImageComponent),
  "albis-react:components/okg/innovationForm": wrap(MagnoliaOKGInnovationForm),
  "albis-react:components/okg/okgMediaApp": wrap(MagnoliaMediaApp),
  "albis-react:components/okg/okgMediaAuthor": wrap(MagnoliaContactCard),
  "albis-react:components/okg/okgLocationSection": wrap(MagnoliaOKGPlaces),
  "albis-react:components/okg/okgDividerText": wrap(MagnoliaOKGDividerText),
  "albis-react:components/okg/okgContentTeaserSection": wrap(
    MagnoliaOKGContentTeaserSection
  ),
  "albis-react:components/okg/okgContactSection": wrap(MagnoliaContactSection),
  "albis-react:components/okg/okgContactStage": wrap(MagnoliaContactStage),
  "albis-react:components/okg/okgImageTextSlider": wrap(
    MagnoliaImageTextSlider
  ),
  "albis-react:components/okg/okgSmallStage": wrap(MagnoliaOKGSmallStage),
  "albis-react:components/okg/okgSmallImageTextTeaser": wrap(
    MagnoliaSmallImageTextComponent
  ),
  "albis-react:components/okg/imageTextComponent": wrap(
    MagnoliaOKGTextImageComponent
  ),
  "albis-react:components/okg/backButton": wrap(MagnoliaBack),
  "albis-react:components/okg/elementSlider": wrap(MagnoliaElementSlider),
  "albis-react:components/okg/headline": wrap(MagnoliaHeadline),
  "albis-react:components/okg/brandTeaser": wrap(MagnoliaBrandTeaser),
  "albis-react:components/okg/searchResults": wrap(SearchResults),
  "albis-react:components/okg/okgPressTeaser": wrap(MagnoliaOKGPressTeaser),
  // HTF
  "albis-react:components/htf/htfContactLocationSection": wrap(
    MagnoliaContactLocationSection
  ),
  "albis-react:components/htf/htfContactStage": wrap(MagnoliaContactStage),
  "albis-react:components/htf/htfContentTeaserSection": wrap(
    MagnoliaContentTeaserSection
  ),
  "albis-react:components/htf/htfDividerText": wrap(MagnoliaHTFDividerText),
  "albis-react:components/htf/htfImageTextTeaser": wrap(
    MagnoliaHTFImageTextTeaser
  ),
  "albis-react:components/htf/htfTextWithImageTeaser": wrap(
    MagnoliaTextWithImageTeaser
  ),
  "albis-react:components/htf/htfProductsApp": wrap(MagnoliaHTFProductApp),
  "albis-react:components/htf/htfNavigation": wrap(MagnoliaHTFNavigation),
  "albis-react:components/htf/htfAnalysisService": wrap(
    MagnoliaHTFAnalysisService
  ),
  "albis-react:components/htf/htfBackButton": wrap(HTFBackButton),
  "albis-react:components/htf/htfBanner": wrap(MagnoliaHTFBanner),
  "albis-react:components/htf/stage": wrap(MagnoliaStage),
  "albis-react:components/htf/imageComponent": wrap(MagnoliaImageComponent),
  // HTF
  "albis-react:components/nfpMainContent": wrap(PlaygroundMainContent),

  // WIPAG
  "albis-react:components/wipag/contentTeaserSection": wrap(
    MagnoliaWIPAGContentTeaserSection
  ),
  "albis-react:components/wipag/contact": wrap(MagnoliaWIPAGContact),
  "albis-react:components/wipag/dividerSlider": wrap(
    MagnoliaWIPAGDividerSlider
  ),
  "albis-react:components/wipag/stage": wrap(MagnoliaWIPAGStage),
  "albis-react:components/wipag/textImageComponent": wrap(
    MagnoliaWIPAGImageTextComponent
  ),
  "albis-react:components/wipag/locations": wrap(MagnoliaWIPAGLocations),
  "albis-react:components/wipag/dividerText": wrap(MagnoliaWIPAGDividerText),
  "albis-react:components/wipag/textWithImageTeaser": wrap(
    MagnoliaWIPAGTextWithImageTeaser
  ),
  "albis-react:components/wipag/caseStudies": wrap(MagnoliaWIPAGCaseStudies),
  "albis-react:components/wipag/smallStage": wrap(MagnoliaWIPAGSmallStage),
  "albis-react:components/wipag/productApp": wrap(MagnoliaWIPAGDistributorApp),
  "albis-react:components/wipag/richTextSection": wrap(
    MagnoliaWIPARichTextSection
  ),
  "albis-react:components/wipag/downloads": wrap(MagnoliaWIPAGDownloads),
  "albis-react:components/wipag/mediaApp": wrap(MagnoliaMediaApp),
  "albis-react:components/wipag/mediaContact": wrap(MagnoliaContactSection),
  "albis-react:components/wipag/combinedContact": wrap(
    MagnoliaWIPAGCombinedContact
  ),
  "albis-react:components/wipag/benefitsSlider": wrap(
    MagnoliaWIPAGBenefitsSlider
  ),
  "albis-react:components/wipag/jobTeaser": wrap(MagnoliaWIPAGJobTeaser),
  "albis-react:components/wipag/headline": wrap(MagnoliaWIPAGHeadline),
  "albis-react:components/wipag/smallImageTextComponent": wrap(
    MagnoliaWIPAGSmallImageTextComponent
  ),
  "albis-react:components/wipag/searchResults": wrap(SearchResults),
  "albis-react:components/wipag/co2Component": wrap(MagnoliaCO2Component),
  "albis-react:components/wipag/recyclingForm": wrap(MagnoliaRecyclingForm),
  "albis-react:components/general/cookiebotReset": wrap(MagnoliaCookiebotReset),
  "albis-react:components/general/videoEmbed": wrap(Magnolia3QEmbed),

  // Client Area
  "albis-react:components/clientArea/clientAreaApp": wrap(
    MagnoliaClientAreaApp
  ),
  "albis-react:components/clientArea/contactFinder": wrap(
    MagnoliaContactFinder
  ),
  // krahn chemie
  "albis-react:components/krahnChemie/animatedStageSlider": wrap(
    MagnoliaAnimatedStageSlider
  ),
  "albis-react:components/krahnChemie/contactLocations": wrap(
    MagnoliaKChLocationsMap
  ),
  "albis-react:components/krahnChemie/dividerSlider": wrap(
    MagnoliaElementSlider
  ),
  "albis-react:components/krahnChemie/dividerText": wrap(MagnoliaDividerText),
  "albis-react:components/krahnChemie/faq": wrap(
    MagnoliaFAQComponentWithContact
  ),
  "albis-react:components/krahnChemie/history": wrap(MagnoliaHistory),
  "albis-react:components/krahnChemie/homeStageSlider": wrap(
    MagnoliaHomeStageSlider
  ),
  "albis-react:components/krahnChemie/imageInfoSlider": wrap(
    MagnoliaImageInfoSlider
  ),
  "albis-react:components/krahnChemie/imageTextComponent": wrap(
    MagnoliaKChImageTextComponent
  ),
  "albis-react:components/krahnChemie/locations": wrap(
    MagnoliaContactLocationSection
  ),
  "albis-react:components/krahnChemie/mediaApp": wrap(MagnoliaMediaApp),
  "albis-react:components/krahnChemie/stage": wrap(MagnoliaStage),
  "albis-react:components/krahnChemie/productsApp": wrap(
    MagnoliaKChProductsApp
  ),
  "albis-react:components/dividerImage": wrap(MagnoliaElementSlider),
  "albis-react:components/krahnChemie/searchResults": wrap(SearchResults),
  "albis-react:components/krahnChemie/servicesStage": wrap(
    MagnoliaServicesStage
  ),
  "albis-react:components/krahnChemie/contentTeaserComponent": wrap(
    MagnoliaContentTeaserComponent
  ),
  "albis-react:components/krahnChemie/factsAndFigures": wrap(
    MagnoliaKChFactsAndFigures
  ),
  "albis-react:components/krahnChemie/downloadsAndLinks": wrap(
    MagnoliaDownloadsAndLinks
  ),
  "albis-react:components/krahnChemie/onePagerNavi": wrap(
    MagnoliaOnePagerHeader
  ),
  "albis-react:components/krahnChemie/eventApp": wrap(MagnoliaEventsApp),
  "albis-react:components/krahnChemie/marketsGallery": wrap(
    MagnoliaTileGallery
  ),
  "albis-react:components/krahnChemie/customerContactForm": wrap(
    MagnoliaCustomerContactFormKCh
  ),
  "albis-react:components/krahnChemie/twoTextComponent": wrap(
    MagnoliaTwoTextComponent
  ),
  "albis-react:components/krahnChemie/imageComponent": wrap(
    MagnoliaImageComponent
  ),

  // otto krahn new business
  "albis-react:components/oknb/okNBHeader": wrap(MagnoliaOKNBNavigation),
  "albis-react:components/mocomDownloads": wrap(MagnoliaDownloadsAndLinks),

  // jobpages
  "albis-react:components/jobpages/jobCTAs": wrap(MagnoliaJobCTAArea),
  "albis-react:components/jobpages/benefits": wrap(MagnoliaBenefits),
  "albis-react:components/jobpages/applicationInfo": wrap(
    MagnoliaApplicationInfo
  ),
  "albis-react:components/jobpages/jobcontact": wrap(MagnoliaJobContact),
  "albis-react:components/jobpages/imagesSlider": wrap(MagnoliaImagesSlider),
  "albis-react:components/jobpages/dynamicJobContent": wrap(
    MagnoliaDynamicJobContent
  ),
  "albis-react:components/jobpages/additionalJobsList": wrap(
    MagnoliaDynamicJobsList
  ),
  "albis-react:components/okg/jobBoard": wrap(MagnoliaJobBoard),
  "albis-react:components/jobpages/floatingApplyButton": wrap(
    FloatingApplyButton
  ),
  "albis-react:components/jobpages/dynamicDepartmentContent": wrap(
    MagnoliaDynamicDepartmentContent
  ),
};
// eslint-disable-next-line
export default {
  componentMappings: config,
};
